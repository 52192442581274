$flag-yellow: #ffd200;
$flag-blue: #1b4392;
$flag-red: #df0a21;

$about: #65c7f0;
$community: #fddd4c;
$tambayan: #41d3be;
$blog: #8dbb71;
$shop: #e07059;
$events: #b1a1ed;

$contact: #769fe4;
$faq: #b1d0ef;
$opportunities: #ffbb45;

$filifest-black: #131212;

$about--light: #fafdff;
