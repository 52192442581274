section.footer {
  min-height: 100%;
  width: 100vw;
  max-width: 2650px;
  position: relative;
  background-color: white;
  z-index: 0;
  overflow-x: hidden !important;

  a {
    color: black;
    text-decoration: none !important;
  }

  .footer__list {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 0;

    .footer__list-item {
      padding: 0.5% 0;
    }

    .footer__list-item--about:hover {
      color: $about;
    }

    .footer__list-item--community:hover {
      color: $community;
    }

    .footer__list-item--blog:hover {
      color: $blog;
    }

    .footer__list-item--shop:hover {
      color: $shop;
    }

    .footer__list-item--events:hover {
      color: $events;
    }

    .footer__list-item--contact:hover {
      color: $contact;
    }
  }

  .footer__socials {
    padding-top: 3%;

    a {
      color: black;
      text-decoration: none;
    }

    a:hover {
      color: $community;
    }
  }
}

section.footer #footer-yellow {
  position: absolute;
  left: 0vw;
  bottom: 0vw;
  object-fit: cover;
  width: 22vw;
  max-width: 300px;
}

section.footer #footer-blue {
  position: absolute;
  right: 0vw;
  top: 0%;
  object-fit: cover;
  width: 40vw;
  max-width: 700px;
}

section.footer #footer-red {
  position: absolute;
  right: 0vw;
  bottom: 0;
  object-fit: cover;
  width: 25vw;
  max-width: 300px;
}

section.footer .footer-bg-img {
  z-index: -1 !important;
}

@media (min-width: 2700px) {
  section.footer {
    min-height: 0 !important;
  }
}

@media (max-width: $large) {
  section.footer {
    min-height: auto;

    .footer__list-item {
      padding: 3% 0 !important;
    }
  }

  section.footer #footer-logo {
    display: flex;
    align-items: center !important;
    justify-content: center;
    padding-bottom: 15%;
    padding-top: 40%;
  }
  section.footer #footer-nav {
    margin-top: 5vh !important;
  }
  section.footer #footer-yellow {
    position: absolute;
    left: 0vw;
    bottom: 0vw;
    object-fit: cover;
    width: 35vw;
  }
  section.footer #footer-blue {
    position: absolute;
    right: 0vw;
    top: 0;
    object-fit: cover;
    width: 100vw;
  }
  section.footer #footer-red {
    position: absolute;
    right: 0vw;
    bottom: 0;
    object-fit: cover;
    width: 35vw;
  }

  .footer__socials {
    padding-top: 20% !important;
  }
  section.footer #copyright {
    font-size: 2.5vw;
  }
}
