.logo-nav-wrapper {
  position: fixed;
  top: 0.5rem;
  left: 1.5rem;
  z-index: 100 !important;
  visibility: hidden;
  transition: all 0.2s;
}

.logo-nav {
  width: 10rem;
  z-index: 5;
}

.nav--closed--logo {
  position: absolute;
  top: 2rem;
  left: 3rem;
  z-index: 5;

  &.hide {
    display: none;
  }
}

.cd-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.nav-but-wrap {
  position: relative;
  display: inline-block;
  float: right;
  top: 2.5rem;
  right: 3rem;
  transition: all 0.3s ease-out;
}

.menu-icon {
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: block;
}

.nav-white {
  .menu-icon__line {
    background-color: white;
  }
}

.menu-icon__line {
  height: 3px;
  width: 30px;
  display: block;
  background-color: #000;
  margin-bottom: 7px;
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, background-color 0.5s ease;
  transition: transform 0.2s ease, background-color 0.5s ease,
    -webkit-transform 0.2s ease;
}

.menu-icon__line-left {
  width: 16.5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon__line-right {
  width: 16.5px;
  float: right;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon__line-white {
  background-color: #fff;
}

.menu-icon__line-black {
  background-color: #000;
}

.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
  width: 30px;
}

.nav {
  position: fixed;
  z-index: 98;
}

.nav:before,
.nav:after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(220, 220, 220, 0.9);
  border-bottom-left-radius: 200%;
  z-index: -1;
  -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    border-radius linear 0.8s;
  transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    border-radius linear 0.8s;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    border-radius linear 0.8s;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    border-radius linear 0.8s;
  -webkit-transform: translateX(100%) translateY(-100%);
  transform: translateX(100%) translateY(-100%);
}

.nav:after {
  background: rgba(255, 255, 255, 1);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.nav:before {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.nav__logo {
}

.nav__content {
  position: fixed;
  visibility: hidden;
  top: 50%;
  margin-top: 20px;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  width: 100%;
  text-align: center;
}

.nav__list {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}

.nav__list-item {
  position: relative;
  display: block;
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
  opacity: 0;
  text-align: center;
  overflow: hidden;
  font-family: cubano, sans-serif;
  font-size: 6vh;
  line-height: 7vh;
  letter-spacing: 1px;
  -webkit-transform: translate(100px, 0%);
  transform: translate(100px, 0%);
  -webkit-transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease-in, transform 0.3s ease;
  transition: opacity 0.3s ease-in, transform 0.3s ease,
    -webkit-transform 0.3s ease;
  margin-top: 0;
  margin-bottom: 0;
}

.nav__list-item a {
  position: relative;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
  display: inline-block;
  text-transform: uppercase;
  -webkit-transition: all 200ms linear;
  transition: all 300ms linear;
}

.nav__list-item #nav-about:hover {
  color: $about !important;
}

.nav__list-item #nav-community:hover {
  color: $community !important;
}

.nav__list-item #nav-blog:hover {
  color: $blog !important;
}

.nav__list-item #nav-shop:hover {
  color: $shop !important;
}

.nav__list-item #nav-opportunities:hover {
  color: $opportunities !important;
}

.nav__list-item #nav-events:hover {
  color: $events !important;
}

.nav__list-item #nav-contact:hover {
  color: $contact !important;
}

.nav__list-item #nav-awards:hover {
  color: $community !important;
}

.nav__list-item #nav-filsoc-awards:hover,
section.filifest-footer #footer-filsoc-awards:hover {
  color: #f2d259 !important;
}

nav.nav-active .logo-nav-wrapper {
  visibility: visible;
}

nav.nav-active .nav__content {
  visibility: visible;
}

nav.nav-active .menu-icon__line {
  background-color: #000;
  -webkit-transform: translate(0px, 0px) rotate(-45deg);
  transform: translate(0px, 0px) rotate(-45deg);
}

nav.nav-active .menu-icon__line-left {
  width: 15px;
  -webkit-transform: translate(2px, 4px) rotate(45deg);
  transform: translate(2px, 4px) rotate(45deg);
}

nav.nav-active .menu-icon__line-right {
  width: 15px;
  float: right;
  -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
  transform: translate(-3px, -3.5px) rotate(45deg);
}

nav.nav-active .menu-icon:hover .menu-icon__line-left,
nav.nav-active .menu-icon:hover .menu-icon__line-right {
  width: 15px;
}

nav.nav-active .nav {
  visibility: visible;
}

nav.nav-active .nav:before,
nav.nav-active .nav:after {
  -webkit-transform: translateX(0%) translateY(0%);
  transform: translateX(0%) translateY(0%);
  border-radius: 0;
}

nav.nav-active .nav:after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

nav.nav-active .nav:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

nav.nav-active .nav__list-item {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: opacity 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
}

nav.nav-active .nav__list-item:nth-child(0) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

nav.nav-active .nav__list-item:nth-child(1) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

nav.nav-active .nav__list-item:nth-child(2) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

nav.nav-active .nav__list-item:nth-child(3) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

nav.nav-active .nav__list-item:nth-child(4) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

nav.nav-active .nav__list-item:nth-child(5) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

nav.nav-active .nav__list-item:nth-child(6) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

nav.nav-active .nav__list-item:nth-child(7) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

@media (max-width: $large) {
  .nav__list-item {
    font-size: 4vh;
  }

  .nav--closed--logo {
    position: absolute;
    top: 2rem;
    left: 1rem;
    z-index: 5;
  }

  .logo-nav-wrapper {
    position: fixed;
    top: 0.5rem;
    left: 0rem;
  }
}
