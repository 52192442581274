section.filifest-awards {
  position: relative !important;
  overflow-x: hidden;
}

.bg-filsoc-awards {
  background: #301f49;
}

.awards-particles,
canvas {
  position: absolute !important;
  width: 100vw !important;
  top: 0;
  height: 10rem;
  z-index: 10 !important;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 10 !important;
  width: 10%;
}

.btn-xl {
  padding: 1rem 3rem;
  font-size: 2rem;
  border-radius: 8px;
  outline-width: thick;
  border-width: 5px;
}

.filsoc-awards__header--img {
  width: 80vw;
  height: 100vh;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0 !important;
}

header.filsoc-awards .container {
  height: 100%;
}

header.filsoc-awards,
.category--header {
  // padding: 20vh 0 15vh 0;
  background: #301f49;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
}

header.filsoc-awards .filsoc-awards-heading,
.filsoc-awards-heading {
  letter-spacing: 0.005em;
  color: #f2d25a;
  text-shadow: 0.4rem 0.4rem 0px #22182e;
}

section.filsoc-awards-footer {
  //background: url(https://storage.googleapis.com/filifest.appspot.com/DisplayImages/filifest-awards-bg-plain-horizontal.png);
  background: #301f49;

  background-size: cover;
  padding: 10rem 0;
}

// section.category--header__heading {
//   background: url(../images/filsoc-awards/categories/category-bg.png) no-repeat;
//   background-size: contain;
//   background-position: center center;
//   width: 100%;
//   height: 100%;
// }

// section.category--header {
//   background: #22182e;
// }

.category-selector {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  z-index: 1;
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    list-style-type: none;
    padding: 1%;
  }
  h3 {
    display: inline;
    list-style-type: none;
    font-size: 0.8vw;
    color: white;
  }
  a {
    text-decoration: none !important;
  }
}

.category--header__vote {
  position: absolute;
  bottom: 0%;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  background-color: #f2d259;
  font-size: 1.25vw;
}

.category--heading {
  width: 50vw;
  height: 50vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0 !important;
}

section.pick-category {
  background-color: white;
  // margin-top: 100vh;
  z-index: 100 !important;
}

section.pick-category .category {
  padding: 5%;
}

section.pick-category .category img {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

/*
.filsoc-awards [type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
*/

/* IMAGE STYLES */

.vote input[type="radio"] {
  cursor: pointer;
  display: none;
  appearance: none;
}

.vote label {
  cursor: pointer;
  transition: all 300ms linear;
}

.vote label:hover {
  color: #f2d259;
  transition: ease;
}

.vote input[type="radio"]:checked {
  display: inline !important;
  width: 2.5rem;
  height: 4rem;
  cursor: pointer;
  content: url(../images/filsoc-awards/awards-trophy.png);
}

.vote input[type="radio"]:checked + span {
  color: #f2d259;
  padding: 0;
  margin: 0;
}

button:disabled,
button[disabled] {
  background-color: #8d8d8d !important;
  border-width: 0px;
}

fieldset[disabled] input,
fieldset[disabled] button,
fieldset[disabled] label {
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .filsoc-awards h1 {
    font-size: 12vw;
  }

  .filsoc-awards__header--img {
    width: 100vw;
  }

  .category--heading {
    width: 100vw;
    height: 100vw;
  }

  .category--header__vote {
    font-size: 6vw;
  }

  .category-selector {
    position: absolute;
    top: 15%;
    left: 0;
    right: auto !important;
    z-index: 1;

    h3 {
      display: inline-block;
      list-style-type: none;
      font-size: 2.5vw;
      padding: 0 2% 0 2%;

      color: white;
    }
  }
}
