h1,
h2,
h3,
h4 {
  font-family: cubano, "Montserrat";
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.25rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 0.75rem;
}

p {
  font-size: 0.9rem;
}
