// Global
@import "./colours.scss";
@import "./screen-sizes";
@import "./typography";
@import "./animations";

// Components
@import "./components/navbar.scss";
@import "./components/footer.scss";

@import "./filsoc-awards.scss";

body {
  overflow-x: hidden !important;
}
